import "./Changelog.scss";

import { ReactComponent as Close } from "../icon/close.svg";
import changes from "../hook/changelog";
import getStateStore from "../hook/state";

export default function Changelog() {
    const state = getStateStore();

    return (
        <div className="comp-changelog">
            <div className="inner">
                <div className="header">
                    <h2>See whats new</h2>
                    <div className="close" onClick={state.closeChangelog}><Close /></div>
                </div>
                <div className="changelog">
                    {new Array(...changes).reverse().map((change, index) => (
                        <div className="version" key={index}>
                            <h3 className="versionNumber">Version {change.version}</h3>
                            <div className="releaseDate">Released on: {`${change.date.getDate()}.${change.date.getMonth() + 1}.${change.date.getFullYear()}`}</div>
                            <div className="changes">
                                {change.changes.map((category, index) => (
                                    <div className="category" key={index}>
                                        <h4>{category.name}</h4>
                                        <ul>
                                            {category.changes.map((item) => (
                                                <li key={item}>{item}</li>
                                            ))}
                                        </ul>
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
}