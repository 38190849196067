import create from "zustand";
import ToDo from "../model/todo";
import pack from "../../package.json";

export type State = {
    todos: ToDo[];
    lastID: number;
    showCompleted: boolean;
    showChangelog: boolean;
    addTodo: (desc: string) => void;
    updateTodo: (todo: ToDo) => void;
    removeTodo: (todo: ToDo) => void;
    toggleShowCompleted: () => void;
    closeChangelog: () => void;
};

const useStateStore = create<State>((set, get) => {
    const channel = new BroadcastChannel("dataChange");
    let todos: ToDo[];
    let lastID: number;
    let showCompleted: boolean;
    let showChangelog: boolean;

    try {
        todos = <ToDo[]>JSON.parse(localStorage.getItem("todos") || "[]");
        lastID = Number(localStorage.getItem("lastID")) || 0;
        showCompleted = !!Number(localStorage.getItem("showCompleted"));
        showChangelog = localStorage.getItem("lastShownChangelog") !== pack.version;
    } catch (error) {
        localStorage.clear();
        todos = [];
        lastID = 0;
        showCompleted = true;
        showChangelog = true;
    }

    try {
        channel.addEventListener("message", (event) => {
            let update;
            try {
                update = JSON.parse(event.data);
            } catch (error) {
                return;
            }

            try {
                if (!update || !update.type) return;
                switch (update.type) {
                    case "todoChange": {
                        set({ todos: update.todos, lastID: update.lastID });
                        break;
                    }
                    case "showCompletedChange": {
                        set({ showCompleted: update.showCompleted });
                        break;
                    }
                }
            } catch (error) {
                window.location.reload();
            }
        });
    } catch (error) {
        console.error(`Cross Tab Sync encountered an error: ${error}`);
    }

    return {
        todos,
        lastID,
        showCompleted,
        showChangelog,
        /**
         * Adds a new todo item to the list
         * @param todo The todo item to be added
         */
        addTodo: (desc: string) => {
            set((state) => {
                const todo = {
                    id: state.lastID + 1,
                    desc,
                    completed: false
                };

                return {
                    todos: [...state.todos, todo],
                    lastID: todo.id
                };
            });

            const state = get();
            localStorage.setItem("todos", JSON.stringify(state.todos));
            localStorage.setItem("lastID", state.lastID.toString());
            channel.postMessage(JSON.stringify({ type: "todoChange", todos: state.todos, lastID: state.lastID }));
        },
        /**
         * Removes a todo item from the list
         * @param todo The todo item to be removed
         */
        removeTodo: (todo: ToDo) => {
            set((state) => ({
                todos: state.todos.filter((t) => t.id !== todo.id)
            }));

            const state = get();
            localStorage.setItem("todos", JSON.stringify(state.todos));
            channel.postMessage(JSON.stringify({ type: "todoChange", todos: state.todos, lastID: state.lastID }));
        },
        /**
         * Updates an existing todo item from the list
         * @param todo
         */
        updateTodo: (todo: ToDo) => {
            set((state) => ({
                todos: state.todos.map((t) => (t.id === todo.id ? { ...todo } : t))
            }));

            const state = get();
            localStorage.setItem("todos", JSON.stringify(state.todos));
            channel.postMessage(JSON.stringify({ type: "todoChange", todos: state.todos, lastID: state.lastID }));
        },
        /**
         * Toggles the showCompleted state
         */
        toggleShowCompleted: () => {
            set((state) => ({
                showCompleted: !state.showCompleted
            }));

            const state = get();
            localStorage.setItem("showCompleted", state.showCompleted ? "1" : "0");
            channel.postMessage(JSON.stringify({ type: "showCompletedChange", showCompleted: state.showCompleted }));
        },
        /**
         * Closes the changelog window
         */
        closeChangelog: () => {
            set({ showChangelog: false });
            localStorage.setItem("lastShownChangelog", pack.version);
        }
    };
});

export default useStateStore;
