import "./App.scss";
import shallow from "zustand/shallow";
import { useState } from "react";
import getState from "./hook/state";

import { ReactComponent as EyeClosed } from "./icon/eyeClosed.svg";
import { ReactComponent as EyeOpen } from "./icon/eyeOpen.svg";
import Todo from "./component/Todo";
import Changelog from "./component/Changelog";

function App() {
  const state = getState((state) => state, shallow);
  const [description, setDescription] = useState("");

  function submitForm() {
    if (description.split(" ").join("") === "") return;
    state.addTodo(description);
    setDescription("");
  }

  return (
    <div id="app">
      <section>
        <nav id="menu">
          <h1>ToDo-App</h1>
          <button id="completeToggle" onClick={state.toggleShowCompleted}>{state.showCompleted ? <EyeClosed /> : <EyeOpen />}<span>{state.showCompleted ? "Hide completed" : "Show completed"}</span></button>
        </nav>
        <div id="content">
          {state.todos.filter((todo) => state.showCompleted ? true : !todo.completed).map((todo) => (
            <Todo todoItem={todo} key={todo.id} />
          ))}
        </div>
        <form id="add" onSubmit={(e) => { e.preventDefault(); submitForm(); }}>
          <input type="text" placeholder="Enter todo description" value={description} onChange={(e) => { setDescription(e.target.value) }} />
          <button type="submit">Add</button>
        </form>
      </section>
      {state.showChangelog && <Changelog />}
    </div>
  );
}

export default App;
