import TodoItem from "../model/todo";
import "./Todo.scss";
import useState from "../hook/state";

import { ReactComponent as Trash } from "../icon/trash.svg";

export interface Props {
    todoItem: TodoItem;
}

export default function Todo(props: Props) {
    const item = props.todoItem;
    const state = useState();


    return (
        <div className="todoItem">
            <input type="checkbox" checked={item.completed} onClick={() => { item.completed = !item.completed; state.updateTodo(item); }} />
            <div className={item.completed ? "done" : ""} onClick={() => { item.completed = !item.completed; state.updateTodo(item); }}>{item.desc}</div>
            <button className="delete" onClick={() => { state.removeTodo(item); }}><Trash /></button>
        </div>
    );
}