export interface ChangelogItem {
    version: string;
    date: Date;
    changes: ChangelogCategory[];
}

export interface ChangelogCategory {
    name: string;
    changes: string[];
}

export const changelog: ChangelogItem[] = [
    {
        version: "0.1.0",
        date: new Date("2022-11-01"),
        changes: [
            {
                name: "New Features",
                changes: ["First release! 🎉", "Added Todo list", "Added Show Completed toggle"]
            }
        ]
    },
    {
        version: "0.2.0",
        date: new Date("2022-11-03"),
        changes: [
            {
                name: "New Features",
                changes: ["Changes in the todo-list now sync across all opened tabs in the same Browser", "After every new release a changelog window will show to inform you about the newest features"]
            }
        ]
    }
];

export default changelog;
